//import { RouteView } from '@/layouts/RouteView'

export default [
      {

          path: 'changePassword',
          name: 'changePassword',
          components:{
            default: () => import('@/views/userManage/changePassword'),
            leftMenu:() => import('@/layouts/leftMenu')
            //leftMenu:() => import('@/views/testMenu'),
          } ,
          meta: {
            title: 'userManage.changePassword'
          }

      },
    {

        path: 'userSymbolList',
        name: 'userSymbolList',
        components:{
            default: () => import('@/views/userManage/userSymbolList'),
            leftMenu:() => import('@/layouts/leftMenu')
            //leftMenu:() => import('@/views/testMenu'),
        } ,
        meta: {
            title: 'userManage.userSymbolList'
        }

    },
    {

        path: 'userAccountList',
        name: 'userAccountList',
        components:{
            default: () => import('@/views/userManage/userAccountList'),
            leftMenu:() => import('@/layouts/leftMenu')
            //leftMenu:() => import('@/views/testMenu'),
        } ,
        meta: {
            title: 'userManage.userSymbolList'
        }

    },
    {

        path: 'userNotify',
        name: 'userNotify',
        components:{
            default: () => import('@/views/userManage/userNotify'),
            leftMenu:() => import('@/layouts/leftMenu')
            //leftMenu:() => import('@/views/testMenu'),
        } ,
        meta: {
            title: 'userManage.userSymbolList'
        }

    },
]
