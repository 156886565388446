<script  setup>
import * as echarts from "echarts";
import { provide } from "vue";
provide("echarts", echarts)
</script>

<template id="app">

  <router-view > </router-view>  
  <br /><br /><br />

  

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin :0 auto;
}
</style>
