import { Button ,Input,InputNumber ,Layout,Menu ,Form,Row,Col, Tabs, Table ,Modal ,Radio ,Calendar,DatePicker,Divider ,Select, } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
//import { tableProps } from 'ant-design-vue/lib/table'

const component= [
    Button,
    Input,
    InputNumber,
    Layout,
    Menu,
    Form,

    Row,
    Col,
    Tabs,
    Table,
    Modal,
    Radio,
    Calendar,
    DatePicker,
    Divider,
    Select,



]
export  function  setupAnt(app){
    component.forEach(component => {
        app.use(component)
    })
       
}