import { request } from '@/utils/request'
import config from '@/config'
//const serviceRequest = require('../config/serviceRequestConfig')

// 登录
export const loginReq = data => {
  return request({
    url: `${config.requestAUTH}/adminLogin`,
    method: 'POST',
    data
  })
}


// 重置密码
export const changePassword = data => request({
  url: `${config.requestUSER}/changePassword`,
  method: 'POST',
  data
})

// 获取用户自选交易品种列表
export const getUserSymbolList = data => request({
  url: `${config.requestUSER}/getUserSymbolList`,
  method: 'POST',
  data
})

// 设置用户自选品种：add 、 delete  、  toTop
export const setUserSymbol = data => request({
  url: `${config.requestUSER}/setUserSymbol`,
  method: 'POST',
  data
})



// 设置用户-账户：getAll 、 add 、 delete  、  toTop
export const dualUserAccount = data => request({
  url: `${config.requestUSER}/dualUserAccount`,
  method: 'POST',
  data
})
// 新增用户通知
export const newUserNotify = data => request({
  url: `${config.requestUSER}/newUserNotify`,
  method: 'POST',
  data
})
// 用户通知任务管理
export const dualUserNotify = data => request({
  url: `${config.requestUSER}/dualUserNotify`,
  method: 'POST',
  data
})
// 查询：用户行情任务
export const getUserQuoteNotify = data => request({
  url: `${config.requestUSER}/getUserQuoteNotify`,
  method: 'POST',
  data
})

// 查询：用户消息清单
export const getUserMessage = data => request({
  url: `${config.requestUSER}/getUserMessage`,
  method: 'POST',
  data
})
// 处理用户消息状态
export const dualUserMessage = data => request({
  url: `${config.requestUSER}/dualUserMessage`,
  method: 'POST',
  data
})


