import Cookies from 'js-cookie'
import config from '@/config'


//const UserKey = 'xls-UserKey'
const UserIdKey = 'xls-admin-user-id'
const UserNameKey = 'xls-admin-user-name'
const TokenKey  = 'xls-admin-Token'



//userid 不用于登录是否有效的判断
export function setUserId(user_id) {
  Cookies.set(UserIdKey, user_id,{expires : config.cookieExpires,})
  return true
}

export function getUserId() {

  if  ( Cookies.get(UserIdKey)  ){
    //console.log("get ok!");
    return   {  UserId:Cookies.get(UserIdKey)}
  }
}

//userid 不用于登录是否有效的判断
export function setUserName(user_name) {
  Cookies.set(UserNameKey, user_name,{expires : config.cookieExpires,})
  return true
}

export function getUserName() {

  if  ( Cookies.get(UserNameKey)  ){
    //console.log("get ok!");
    return   {  UserName:Cookies.get(UserNameKey)}
  }
}
//token：判断登录状态


export function setUserToken(token) {
  Cookies.set(TokenKey, token,{expires : config.cookieExpires,})
  return true
}
export function getUserToken() {

  if  ( Cookies.get(TokenKey)  ){
    //console.log("get ok!");
    return   {  Token:Cookies.get(TokenKey)}
  }


}

export function removeUserToken() {


  Cookies.remove(TokenKey)


  return
}
