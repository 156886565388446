import { createRouter,createWebHashHistory } from 'vue-router'
//import { createRouter } from 'vue-router'
import routeMap from './modules/index'
//import panguRouteMap  from './pangu-router/index'
//import routeMap_Trade from './modules/trade'

import { useUserStore }  from "@/stores/index.js"



const  routes  = 
[
    {
        path:  '/',
        name: 'home',
        redirect:'/main'
    },
    {
        path:  '/main',
        name: 'main',
        component:  () => import('@/layouts/mainFrame.vue'),  
        redirect:'/main/firstPage',
        children:[
            ...routeMap
        ],
        beforeEnter: (to, from, next ) => {
            const    userStore  =  useUserStore  ();
            if (userStore.getLoginStatus()  ){//已登录，正常访问
                next()
            }
            else {                //未登录，跳转登录页
                next({name: 'login'})
            }
        }

    },

    {
        path:  '/tradeHome',
        name: 'tradeHome',
        component:  () => import('@/views/trade/tradeHome.vue'),

        beforeEnter: (to, from, next ) => {
            const    userStore  =  useUserStore  ();
            if (userStore.getLoginStatus()  ){//已登录，正常访问
                next()
            }
            else {                //未登录，跳转登录页
                next({name: 'login'})
            }
        }
    },


    {
        path:  '/ProjectManage/:id',
        name: 'ProjectManage',
        component:  () => import('@/views/project/ProjectManage.vue'),

        beforeEnter: (to, from, next ) => {
            const    userStore  =  useUserStore  ();
            if (userStore.getLoginStatus()  ){//已登录，正常访问
                next()
            }
            else {                //未登录，跳转登录页
                next({name: 'login'})
            }
        }
    },
    {
        path:  '/tradeAccount/:id',
        name: 'tradeAccount',
        component:  () => import('@/views/trade/tradeAccount.vue'),

        beforeEnter: (to, from, next ) => {
            const    userStore  =  useUserStore  ();
            if (userStore.getLoginStatus()  ){//已登录，正常访问
                next()
            }
            else {                //未登录，跳转登录页
                next({name: 'login'})
            }
        }
    },


    //============================================用户：登录与退出登录==============================================================


    {
        path:  '/login',
        name:   'login',
        component:  () => import('@/views/login/loginView.vue'),
        beforeEnter: (to, from, next ) => {
            const    userStore  =  useUserStore  ();
            userStore.userStoreLogout();
            next();
        }
    },

    {
        path:  '/logout',
        name:   'logout',       
        beforeEnter: (to, from, next ) => {
            const    userStore  =  useUserStore  ();
            userStore.userStoreLogout();
            next(  {name: 'login'}  );
        }

    },

    {
        path:  '/about',
        component: () => import('@/views/commonPages/aboutUs.vue')
    },
   
    {
        path:  '/:path(.*)',
        component:  () => import('@/views/commonPages/notFound.vue')
    }
        
        
]

//console.log(routes)


const router = createRouter({
        history : createWebHashHistory(),
        mode :'hash',
        routes
}

)


export default router
