//import { RouteView } from '@/layouts/RouteView'

export default [
/*
  {

      path: 'runningManage',
      name: 'runningManage',
      redirect:'/kdataSignal',
      meta: {
        title: 'runningManage'
      }
  },
  */
  {
    path: 'userMessage',
    name: 'userMessage',
    components:{
      default: () => import('@/views/runningManage/userMessage'),
      leftMenu:() => import('@/layouts/leftMenu')
      //leftMenu:() => import('@/views/testMenu'),
    }
  },
  {
    path: 'kdataSignal',
    name: 'kdataSignal',
    components:{
      default: () => import('@/views/runningManage/kdataSignal'),
      leftMenu:() => import('@/layouts/leftMenu')
      //leftMenu:() => import('@/views/testMenu'),
    } 
  },
  {
    path: 'orderManage',
    name: 'orderManage',
    components:{
      default: () => import('@/views/runningManage/orderManage'),
      leftMenu:() => import('@/layouts/leftMenu')
      //leftMenu:() => import('@/views/testMenu'),
    } ,
    meta: {
      title: 'runningManage.orderManage'
    }  
  }
]
