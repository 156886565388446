
export default [
   {
    menuKey     :   '1',
    menuName    :   '首页-总览',
    routePath   :   '/',
    pageTitle   :    '首页-总览',
   },
   {
      menuKey     :   '1',
      menuName    :   '首页-总览',
      routePath   :   '/main/firstPage',
      pageTitle   :    '首页-总览',
     },
   {
    menuKey     :   '21',
    menuName    :   '运行管理-量化信号',
    routePath   :   '/main/kdataSignal',
    pageTitle   :    "运行管理 / 背离信号",
   },
   {
    menuKey     :   '22',
    menuName    :   '运行管理-交易管理',
    routePath   :   '/main/orderManage',
    pageTitle   :    '运行管理 / 交易管理',

   },
    {
        menuKey     :   '23',
        menuName    :   '运行管理-消息通知',
        routePath   :   '/main/userMessage',
        pageTitle   :    '运行管理 / 消息通知',

    },
   {
    menuKey     :   '31',
    menuName    :   '系统管理-账户配置',
    routePath   :   '/main/accountConfig',
    pageTitle   :    '系统管理 / 账户配置',
   },
    {
        menuKey     :   '32',
        menuName    :   '交易代码配置',
        routePath   :   '/main/symbolConfig',
        pageTitle   :    '系统管理 / 交易代码配置',
    },
   {
    menuKey     :   '321',
    menuName    :   '系统管理-品种配置-品种代码配置',
    routePath   :   '/main/symcodeConfig',
    pageTitle   :    '系统管理 / 品种配置 / 品种-代码',
    
   },
   {
    menuKey     :   '322',
    menuName    :   '系统管理-品种配置-交易品种配置',
    routePath   :   '/main/symbolConfig',
    pageTitle   :    '系统管理 / 品种配置 / 交易-品种',
   },
   {
      menuKey     :   '323',
      menuName    :   '系统管理-品种配置-交易月份',
      routePath   :   '/main/trmonthConfig',
      pageTitle   :    '系统管理 / 品种配置 / 交易月份',
     },   
   {
    menuKey     :   '33',
    menuName    :   '系统管理-初始化数据',
    routePath   :   '/main/dataInitial',
    pageTitle   :    '系统管理 / 初始化数据',

   },
   {
    menuKey     :   '41',
    menuName    :   '个人设置-修改密码',
    routePath   :   '/main/changePassword',
    pageTitle   :    '个人设置 / 修改密码',
   },
   {
    menuKey     :   '42',
    menuName    :   '个人设置-退出系统',
    routePath   :   '/logout',
    pageTitle   :    '退出系统',
   },
    {
        menuKey     :   '43',
        menuName    :   '个人设置-自选品种',
        routePath   :   '/main/userSymbolList',
        pageTitle   :    '个人设置 / 自选品种',
    },
    {
        menuKey     :   '44',
        menuName    :   '个人设置-关注账户',
        routePath   :   '/main/userAccountList',
        pageTitle   :    '个人设置 / 关注账户',
    },
    {
        menuKey     :   '45',
        menuName    :   '个人设置- 通知设置',
        routePath   :   '/main/userNotify',
        pageTitle   :    '个人设置 / 通知设置',
    },
//==========================================================盘古子系统配置begin============================================
    {
        menuKey     :   '10001',
        menuName    :   '首页',
        routePath   :   '/pangu/panguHome',
        pageTitle   :   '盘古-首页',
    },
    {
        menuKey     :   '10021',
        menuName    :   '回测',
        routePath   :   '/pangu/panguBacktest',
        pageTitle   :   '盘古-回测',
    },
    {
        menuKey     :   '10022',
        menuName    :   '回测',
        routePath   :   '/pangu/panguBacktestRunConfig',
        pageTitle   :   '盘古-回测-运行策略配置',
    },
    {
        menuKey     :   '10031',
        menuName    :   '账户配置',
        routePath   :   '/pangu/panguConfigAccount',
        pageTitle   :   '盘古-账户配置',
    },
    {
        menuKey     :   '10032',
        menuName    :   '电台配置',
        routePath   :   '/pangu/panguConfigStation',
        pageTitle   :   '电台配置',
    },
    {
        menuKey     :   '10033',
        menuName    :   '频道配置',
        routePath   :   '/pangu/panguConfigChannel',
        pageTitle   :   '频道配置',
    },
    {
        menuKey     :   '10034',
        menuName    :   '策略配置',
        routePath   :   '/pangu/panguConfigStrategy',
        pageTitle   :   '策略配置',
    },
    {
        menuKey     :   '10035',
        menuName    :   '参数组配置',
        routePath   :   '/pangu/panguConfigGroup',
        pageTitle   :   '参数组配置',
    },
//==========================================================盘古子系统配置end============================================
]