/**参数配置：
  *title：                        -标题
  *BASE_URL：                     -后端请求地址前缀
  *cookieExpires:                 -cookie有效期
  *requestTimeOut:                -请求超时时长
  * loginRoutePath：               -登录页路由配置
  
  *==>请求服务配置：调用后端服务名称/地址：
  *requestAUTH:                   -认证服务
  *requestTSS:                    -交易服务
  *requestOSS:                    -运营服务
 */


export default {
    title:                '盘小古',

    /* local
    BASE_URL                :   'http://192.168.8.108:8089/api/',
    WEBSOCKET_URL           :   'ws://192.168.8.108:8600/webconn',
    WEBSOCKET_BASE_URL      :   'ws://192.168.8.108:',


    //BASE_URL                :   'http://127.0.0.1:8089/api/',
    //WEBSOCKET_URL           :   'ws://127.0.0.1:8600/webconn',
    //WEBSOCKET_BASE_URL      :   'ws://127.0.0.1:',
    // 盘古-local
    PANGU_BASE_URL          :   'http://192.168.8.149:4879',
    //PANGU_BASE_URL          :   'http://stars.weepk.com:4879',
*/
    /*  server:*/

    //BASE_URL                :   'http://xls.weepangu.cn:8086/api/'       ,
    /*local*/
    //BASE_URL                :   'http://192.168.8.108:8099/api/',
    BASE_URL                :   'http://43.14.107.220:8099/api/',
    //BASE_URL                :   'http://127.0.0.1:8099/api/',
    //WEBSOCKET_URL           :   'ws://1.15.239.241:8600/webconn'      ,
    //WEBSOCKET_BASE_URL      :   'ws://1.15.239.241:'                  ,
    // 盘古-server
    //PANGU_BASE_URL          :   'https://stars.weepangu.cn:4879',
    //PANGU_BASE_URL          :   'http://192.168.8.149:4879',
    PANGU_BASE_URL          :   'http://127.0.0.1:4879',

    cookieExpires           :    2,
    requestTimeOut          :    60000,
    loginRoutePathName      :    'login',
    mainRoutePathName       :    'firstPage',

    requestAUTH                 :    'auth',
    requestCOMMON               :    'common',
    requestUSER                 :    'usr',
    requestSYSCONFIG            :    'sysConfig',
    requestACCOUNT              :    'account',
    requestAST                  :    'ast',
    requestASP                  :    'asp',
    requestTRADE                  :    'trade',
    tqRuntimeData_request_dir   :    'tqRuntimeData',
    kdata_request_dir           :    'kdata',

    request_BACKTEST_URL        :    'backtest',
    requestSYMBOL               :    'symbol',
    requestTSS                  :    'xls-tss',
    requestOSS                  :    'xls-oss',

    RETURN_CODE_SUCCESS         :     200,
    RETURN_CODE_FAILED          :     201,
    RETURN_CODE_UNPASS          :     301,     //'校验未通过，如：token无效；'
    RETURN_CODE_ISNULL          :     302,     //'空值，无数据；如：无关注品种；'
    RETURN_CODE_EXCEPT          :     303,     //'异常，服务侧执行异常；'
    RETURN_CODE_NOTLOGIN        :     304,
    RETURN_CODE_NOT_TRADETIME   :     601,     //'非交易时段；'
    RETURN_CODE_NOT_LOGIN       :     602,     //'未登录或登录失效；'
    RETURN_CODE_CONNECTTED      :     603 ,    //连接成功
    RETURN_CODE_PANGU           :     0 ,       //连接成功

    MIN_DUR                     :     15,
    MAX_DUR                     :     604800,

}




