import axios from 'axios'
import config from '@/config'
//import { useUserStore } from '../store'
import router from '../router'
import message from 'ant-design-vue/es/message'
import { getUserToken } from '@/utils/auth'


const instance = axios.create({
    withCredentials: false,
    baseURL: config.BASE_URL,
    timeout: config.requestTimeOut,
    headers: {
      get: {
        'Access-Control-Allow-Origin':'*',  //解决cors头问题
        'Access-Control-Allow-Credentials':'true', //解决session问题
        "Access-Control-Allow-Header":"Content-Type,Access-Token",
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
      },
      post: {
        'Access-Control-Allow-Origin':'*',  //解决cors头问题
        'Access-Control-Allow-Credentials':'true', //解决session问题
        "Access-Control-Allow-Header":"Content-Type,Access-Token",
        'Content-Type': 'application/x-www-form-urlencoded', //将表单数据传递转化为form-data类型
          'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
      }
    }
})


// 请求拦截
instance.interceptors.request.use(config => {
    //console.log('请求拦截：');
    if (getUserToken()) {
          //config.headers['adminUserId'] = getUserToken().UserId; 
            config.headers['adminToken'] = getUserToken().Token;
            //config.headers.adminToken=getUserToken().Token;
            //console.log('adminToken：', getUserToken().Token);

    }
    //console.log('请求信息：',config);
    
    //config.headers.token=userStore  .  getStoreToken();
    return config
}, err => {
    return Promise.reject(err)
})




// 响应拦截
instance.interceptors.response.use(response => {
    const res = response.data

        if (res.code == config.RETURN_CODE_NOTLOGIN){
                router.push({name:  config.loginRoutePathName});
                return

        }
    /*
            //console.log(res)

           if (res && res.code && res.code !== 0) {
             if (res.code === 401) {
               store.dispatch('logout').then(() => {
                 router.push({
                   path: config.loginRoutePath,
                   query: {
                     redirect: router.currentRoute.fullPath
                   }
                 })
               })
             }
             if (!response.config.hideErrorPrompt) {
               message.warning(errorStatus(res.message))
             }
             return Promise.reject(response)
           }
           */
  
    return Promise.resolve(
      response.config.originResponse
        ? response
        : res
    )
  }, err => {
   
    if (err?.response?.data) {
      const errRes = err.response.data
      // if ((errRes?.status && errRes.status === 404) || errRes.indexOf('404') > -1) {
      //   message.warning('可能正在重启服务，请稍等！')
      //   // 这边要resetToken并跳转至登录页
      //   store.dispatch('user/resetToken').then(() => {
      //     location.reload() // 为了重新实例化vue-router对象 避免bug
      //   })
      // } else {
      //   message.error(errRes.message)
      // }
  
      message.error(errRes.message)
      return Promise.reject(errRes)
    }
    if (JSON.stringify(err).indexOf('timeout') > -1) {
      message.warning('请求超时，请重试！')
    }
    if (axios.isCancel(err)) {
      err.selfCancel = true
    }
  
    return Promise.reject(err)
  })
  
  export {
    instance as request
  }
  