import firstPageRoutemap  from './firstPageRoutemap'    //首页-总览
import runningManageRoutemap from './runningManageRoutemap'     //运行管理
import systemManageRoutemap from './systemManageRoutemap'     //系统管理
import userManageRoutemap from './userManageRoutemap'     //个人设置

const routeMap = [
  ...firstPageRoutemap,
  ...runningManageRoutemap,
  ...systemManageRoutemap,
  ...userManageRoutemap,
]

export default routeMap
