//import { RouteView } from '@/layouts/RouteView'

export default [
  /*
  {

      path: '/systemManage',
      name: 'systemManage',
      redirect:'/accountConfig',
      meta: {
        title: 'systemManage'
      }
    
  },
  */
  {
    path: 'accountConfig',
    name: 'accountConfig',
    components:{
      default: () => import('@/views/systemManage/accountConfig'),
      leftMenu:() => import('@/layouts/leftMenu')
      //leftMenu:() => import('@/views/testMenu'),
    } ,
    meta: {
      title: 'systemManage.accountConfig'
    }  
  },
  {
    path: 'symbolConfig',
    name: 'symbolConfig',
    components:{
      default: () => import('@/views/systemManage/symbolConfig'),
      leftMenu:() => import('@/layouts/leftMenu')
      //leftMenu:() => import('@/views/testMenu'),
    } ,
    meta: {
      title: 'systemManage.symbolConfig'
    }  
  },
  {
    path: 'trmonthConfig',
    name: 'trmonthConfig',
    components:{
      default: () => import('@/views/systemManage/trmonthConfig'),
      leftMenu:() => import('@/layouts/leftMenu')
      //leftMenu:() => import('@/views/testMenu'),
    } ,
    meta: {
      title: 'systemManage.trmonthConfig'
    }  
  },
  {
    path: 'dataInitial',
    name: 'dataInitial',
    components:{
      default: () => import('@/views/systemManage/dataInitial'),
      leftMenu:() => import('@/layouts/leftMenu')
      //leftMenu:() => import('@/views/testMenu'),
    } ,
    meta: {
      title: 'systemManage.dataInitial'
    }  
  }
]
