import App from './App.vue'
import { createApp } from 'vue'
import { createPinia  } from 'pinia'
import  router  from  './router'
import  { setupAnt }  from   '@/ant-design-vue/index.js'
import { echarts}  from   '@/utils/echarts'

import 'ant-design-vue/dist/antd.css'


const  app = createApp(App)
const  pinia= createPinia()
app.config.productionTip = false;
app.config.globalProperties.$echarts = echarts

setupAnt(app)

app.use(router)
app.use(pinia)

app.mount('#app')