import { ref } from 'vue'
import { defineStore } from 'pinia'
import { loginReq    } from '../api/user.js'
import { message     } from 'ant-design-vue'
import config from '@/config'
import router from '@/router/index.js'
import menuRoutePath  from '@/config/menuRoutePath.js';
import {setUserToken, getUserToken, removeUserToken, setUserId,setUserName} from '@/utils/auth'



export const useUserStore = defineStore ('user',   () => {
    //const   isLogin     =   ref( false );
    //const   userId      =   ref( '' );
    const   userToken   =   ref( '' );


    function    getLoginStatus(){
        //console.log('useUserStore.getLoginStatus():获取到Cookie信息',getUserToken());
        if (  (getUserToken() )){
            return true;
        }else{
            return false;
        }
    }
    function    getStoreToken(){
        return getUserToken();
    }    
    function   userStoreLogin  (  params  )  {     
        return new Promise((resolve, reject) => {
            loginReq(params)
              .then(res => {
                //console.log(res.code)
                if (  res.code == config.RETURN_CODE_SUCCESS )  {
                    //登录成功；修改登录状态，页面跳转：首页
                    //console.log(res);
                    //userId.value    = res.userId
                    userToken.value = res.data.token;
                    //message.warning( config.mainRoutePathName)


                    //setUserToken(res.userId,  res.data.token)
                    setUserToken( res.data.token);
                    setUserId   (res.data.userId);

                    setUserName   (res.data.userName);

                    //console.log(res.data.token)

                    //const currentPath=router.currentRoute.value.fullPath;
                    //console.log(currentPath);

                    router.push({name:  config.mainRoutePathName})

                }                 
                else  
                {   
                    if (  res.code == config.RETURN_CODE_UNPASS )    {
                        //登录失败，用户名密码错误，message提示
                        message.warning('用户名/密码错误！');
                    }
                    else{
                        //其他失败，message提示
                        message.warning('登录失败，请重试或联系系统管理员!')
                    }
                }

                resolve(res)
              }).catch(err => {
                message.warning('登录失败，请重试或联系系统管理员!')
                reject(err)
              })
          })
    }
    function   userStoreLogout  ( )  {   
        //console.log('before:'+getToken());
        removeUserToken(); 
       
        //console.log('after:'+getToken());
    }
    
    return  {  getLoginStatus,  userStoreLogin   ,  userStoreLogout   ,  getStoreToken }
})





export const useMenuStore = defineStore ('menu',   () => {
  
    /*
    const   mode  =   ref('inline');
    const   theme  =   ref('light');
    const   selectedKeys  =   ref( ['1']);
    const   openKeys  =   ref(['sub1']);  
    */
    const state = ref({
        mode: 'inline',
        theme: 'light',
        selectedKeys: [getCurpathMenukey()],
        //openKeys: getCurpathOpenkeys(),
        openKeys: ['20','30','320','40'],
    });

    function  getCurpathMenukey  (){
        
        //console.log(router.currentRoute.value.fullPath);
        let selectedMenu = {};
        selectedMenu  =  menuRoutePath.find((item)=>{
                return  item.routePath ==  router.currentRoute.value.fullPath
        });
        //console.log(selectedMenu);

        //设置menuStore的菜单选中项
        //setSelectedMenuKey ( selectedMenu.menuKey);
        //
        if  (selectedMenu)
                return  selectedMenu.menuKey;
        else 
                return '21';
        //state.value.selectedKeys  = [selectedMenu.menuKey];       
        
    }
    function  getCurpathPageTitle  (){
        
        //console.log(router.currentRoute.value.fullPath);
        let selectedMenu = {};
        selectedMenu  =  menuRoutePath.find((item)=>{
                return  item.routePath ==  router.currentRoute.value.fullPath
        });
        //console.log(selectedMenu);

        //设置menuStore的菜单选中项
        //setSelectedMenuKey ( selectedMenu.menuKey);
        return  selectedMenu.pageTitle;
        //state.value.selectedKeys  = [selectedMenu.menuKey];       
        
    }
    function  getCurpathOpenkeys  (){
        let menuKey=getCurpathMenukey();
        let len=menuKey.length;
        let openKeys=[];
        //console.log(menuKey.length);
        //console.log(len);
        while (len-- >1){
            //console.log(menuKey+'-'+len);
            openKeys.push (menuKey.substring(0,len)+'0');
        }
        return openKeys;

    }
    
    //查找指定menuKey的跳转链接Routepath，同时设置为当前菜单项
    function    getMenukeyRoutePath(  menuKey)
    {
            
            //查找菜单项
            let selectedMenu = {};
            selectedMenu  =  menuRoutePath.find((item)=>{
                    return  item.menuKey ==  menuKey
            });
            //console.log(selectedMenu);

            //设置menuStore的菜单选中项
            //setSelectedMenuKey ( menuKey);
            return selectedMenu.routePath;
            
    }

    return  { state  ,  getMenukeyRoutePath  ,getCurpathOpenkeys  , getCurpathPageTitle  }
})
