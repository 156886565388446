//import { RouteView } from '@/layouts/RouteView'

export default [
    {
  
        path: 'firstPage',
        name: 'firstPage',
        components:{
            default: () => import('@/views/firstPage/firstPageView'),
            leftMenu:() => import('@/layouts/leftMenu')
            //leftMenu:() => import('@/views/testMenu'),
        } ,
        meta: {
          title: 'firstPage'
        }
      
    }
  ]
  